import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { initTelegram, getRequestData, openTelegramLink, inviteUserToChannel, getInviteText, getInviteLink, getChannelLink } from './tools/TgTools';
import { postData } from './communications/Backend';
import { Rules } from './popups/Rules';
import { Invitation } from './popups/Invitation';
import { Tasks } from './popups/Tasks';
import { trackEvent, trackException } from './tools/GaTools';
import Withdraw from './popups/Withdraw';
import LoadingSpinner from './components/LoadingSpinner';
import PopupCard from './components/PopupCard';
import PopupTitle from './components/PopupTitle';
import PopupContent from './components/PopupContent';
import PopupInfo from './components/PopupInfo';
import ButtonSingle from './components/ButtonSingle';
import LinearProgressBar from './components/LinearProgressWithLabel'
import ButtonWithLogo from './components/ButtonWithLogo';
import ButtonWithIcon from './components/ButtonWithIcon';
import ButtonPair from './components/ButtonPair';
import SlotMachine from './anim/SlotMachine';
import './App.css';

function App() {
    const officialChannelUrl = useRef(null);
    const miniAppVisitUrl = useRef(null);
    const upiPayEnable = useRef(false);
    const [machineActive, setMachineActive] = useState(false);
    const [state, setState] = useState({
        initState: 'loading', // 'loading', 'success'
        loginState: 'loading', // 'loading', 'successJoined', 'successUnjoined', 'check', 'error'
        spinState: 'idle', // 'idle', 'loading', 'insufficient', 'withdraw', 'spinning', 'finished', 'error'

        openRules: false,
        openInvitation: false,
        openWithdraw: false,
        openTask: false,

        ticketNumber: 0,
        rewards: 0,

        price: 0,
        priceShow: 0,
        progress: 0,
        progressShow: 0,
        progressMax: 0,
        progressMaxShow: 0,
        newWithdrawPending: 0,
    });
    const [taskInfos, setTaskInfos] = useState([]);

    const { initState, loginState, spinState,
        openRules, openInvitation, openWithdraw, openTask, 
        ticketNumber, rewards,
        price, priceShow, progress, progressShow, progressMax, progressMaxShow,
        newWithdrawPending } = state;

    const updateState = useCallback((newState) => {
        setState(prevState => ({
            ...prevState,
            ...newState
        }));
    }, [setState]);

    const getTicketNumber = () => ticketNumber;

    const updateTicketNumber = (newTicket) => {
        updateState({ ticketNumber: newTicket });
    }

    const checkLoginState = useCallback(async () => {

        trackEvent({
            category: 'User',
            action: 'Login',
            label: "StartCheck",
        });

        try {
            await postData(
                'login',
                null,
                (data) => {
                    if ('officialChannelUrl' in data) {
                        officialChannelUrl.current = data.officialChannelUrl;
                    }
                    if ('miniAppUrl' in data) {
                        miniAppVisitUrl.current = data.miniAppUrl;
                    }
                    if ('isUpiMode' in data) {
                        upiPayEnable.current = data.isUpiMode;
                    }

                    const loginState = data.isInChannel ? 'successJoined' : 'successUnjoined'
                    updateState({
                        loginState: loginState,
                        ticketNumber: data.ticketNumber,
                        progress: data.progress,
                        progressShow: data.progress,
                        progressMax: data.progressMax,
                        progressMaxShow: data.progressMax,
                        price: data.price,
                        priceShow: data.price,
                    });

                    setTaskInfos([])
                    if (data.taskInfos) {
                        setTaskInfos(prevTaskInfos => {
                            return data.taskInfos.map(taskInfo => ({
                                channelId: taskInfo.channelId,
                                channelName: taskInfo.channelName,
                                channelLink: taskInfo.channelLink,
                                state: 'join'
                            }));
                        });
                    }

                    console.log('User Login Success');
                    trackEvent({
                        category: 'User',
                        action: 'Login',
                        label: loginState,
                    });
                },
                (error) => {
                    updateState({ loginState: 'error' });
                    console.error('User Login Failed', error);
                    trackException({
                        description: `User Login Failed: ${error}`,
                        fatal: true,
                    });
                }
            );
        } catch (error) {
            updateState({ loginState: 'error' });
            console.error('User Login Failed Catched', error);
            trackException({
                description: `User Login Failed: ${error}`,
                fatal: true,
            });
        }
    }, [updateState]);

    const handleSpinClose = useCallback(() => {
        updateState({
            spinState: 'idle',
            priceShow: price,
            progressShow: progress,
            progressMaxShow: progressMax,
        });
        trackEvent({
            category: 'User',
            action: 'Spin',
            label: 'ResumeIdle',
            value: progress,
        });
    }, [updateState, progress, progressMax]);

    const handleSpinStop = useCallback(() => {
        setMachineActive(false);
        const spinState = (newWithdrawPending > 0) ? 'withdraw' : 'finished';
        updateState({
            spinState: spinState,
        });
        trackEvent({
            category: 'User',
            action: 'Spin',
            label: `Stop-${spinState}`,
            value: progress,
        });
    }, [updateState, newWithdrawPending]);


    const handleRuleButtonPress = () => {
        updateState({ openRules: true });
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "Rule",
        });
    };

    const handleInvitationButtonPress = () => {
        updateState({ openInvitation: true });
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "Invitation",
        });
    };

    const handleWithdrawButtonPress = () => {
        updateState({ openWithdraw: true });
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "Withdraw",
        });
    };

    const handleTaskButtonPress = () => {
        updateState({ openTask: true });
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "Task",
        });
    };

    const handleSpinButtonPress = useCallback(async () => {
        if (machineActive === true)
            return;

        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: "Play",
        });

        updateState({ spinState: 'loading' });

        try {
            await postData(
                'spin',
                null,
                (data) => {
                    let spinState = 'error'
                    if (data.state === 'success')
                        spinState = 'spinning';
                    else if (data.state === 'insufficient')
                        spinState = 'insufficient';

                    updateState({
                        spinState: spinState,
                        ticketNumber: data.ticketNumber,
                        progress: data.progress,
                        progressMax: data.progressMax,
                        price: data.price,
                        rewards: data.rewards,
                        newWithdrawPending: data.newWithdrawPending,
                    });

                    if (spinState === 'spinning') {
                        setMachineActive(true);
                    }

                    trackEvent({
                        category: 'User',
                        action: 'Spin',
                        label: data.state,
                        value: data.rewards,
                    });
                },
                (error) => {
                    console.error('Failed to spin:', error);
                    updateState({ spinState: 'error' });
                    trackException({
                        description: `User Spin Failed: ${error}`,
                    });
                }
            );
        } catch (error) {
            console.error('Failed to spin catched:', error);
            updateState({ spinState: 'error' });
            trackException({
                description: `User Spin Failed: ${error}`,
            });
        }
    }, [machineActive, updateState])

    const handleInvite = () => {
        trackEvent({
            category: 'User',
            action: 'ButtonClick',
            label: 'Invite',
        });
        let link = null;
        if (miniAppVisitUrl.current === null) {
            link = getInviteLink();
        } else {
            link = miniAppVisitUrl.current;
        }
        inviteUserToChannel(link, getInviteText(progress));
    }

    const handleLoginCheck = useCallback(() => {
        updateState({ loginState: 'loading' });
        checkLoginState();
    }, [checkLoginState, updateState]);

    const handleJoinChannel = useCallback(() => {
        updateState({ loginState: 'check' });
        let link = null;
        if (officialChannelUrl.current === null) {
            link = getChannelLink();
        } else {
            link = officialChannelUrl.current;
        }

        openTelegramLink(link);
    }, [updateState]);

    useEffect(() => {
        initTelegram();

        const intervalId = setInterval(() => {
            if (getRequestData() !== null) {
                trackEvent({
                    category: 'User',
                    action: 'Init',
                    label: 'Finished',
                });
                updateState({ initState: 'success' });
                handleLoginCheck();
                clearInterval(intervalId);
            } else {
                trackEvent({
                    category: 'User',
                    action: 'Init',
                    label: 'Retry',
                });
            }
        }, 200);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const renderLoading = () => <LoadingSpinner />;

    const renderJoinUs = () => (
        <PopupCard open={true}>
            <PopupTitle title="Join Us" />
            <PopupContent>
                <PopupInfo info="Join our channel first." />
            </PopupContent>
            <ButtonSingle onClick={handleJoinChannel} clickInfo="Join" />
        </PopupCard>
    );

    const renderJoinCheck = () => (
        <PopupCard open={true}>
            <PopupTitle title="Join Us" />
            <PopupContent>
                <PopupInfo info="Join our channel first." />
            </PopupContent>
            <ButtonSingle onClick={handleLoginCheck} clickInfo="Continue" />
        </PopupCard>
    );

    const renderLoginError = () => {
        const info = `Network connection error
                      Try again later!`;
        return (
            <PopupCard open={true}>
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={handleLoginCheck} clickInfo="Retry" />
            </PopupCard>
        );
    };

    const renderSpinError = () => {
        const info = `Network connection error
                      Try again later!`;
        return (
            <PopupCard open={true}>
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={handleSpinClose} clickInfo="Retry" />
            </PopupCard>
        );
    };

    const renderSpinFinished = () => {
        const info = `You have get ₹${rewards} for this slot!
                      Totally you have get ₹${progress},
                      earn another ₹${progressMax - progress} to withdraw`;
        return (
            <PopupCard open={true}>
                <PopupTitle title="Congratulations" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={handleSpinClose} clickInfo="Continue" />
            </PopupCard>
        );
    };

    const renderInsufficientTickets = () => {
        //const info = `Invite Friends and get Gems!
        //              For this slot, you need ${price} gems to play.
        //              You have ${ticketNumber} gems now.`;
        const info = (
            <>
                Invite Friends and get more
                <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                {'\n'}
                Play this slot need
                <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                x{price}.
                {'\n'}
                Now you have 
                <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                x{ticketNumber}.
            </>
        );
        return (
            <PopupCard open={true} >
                <PopupTitle title="Insufficient Gems" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonPair
                    confirmInfo="Invite"
                    closeInfo="Close"
                    onConfirm={() => {
                        handleInvite();
                        handleSpinClose();
                    }}
                    onClose={handleSpinClose}
                />
            </PopupCard >
        );
    };

    const renderNeedWithdraw = () => {
        const info = `You have get ₹${rewards} for this slot!
                      Totally you have get ₹${newWithdrawPending},
                      Come to cash out or continue play`;
        return (
            <PopupCard open={true} >
                <PopupTitle title="Congratulations" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonPair
                    confirmInfo="Cash Out"
                    closeInfo="Continue"
                    onConfirm={() => {
                        handleSpinClose();
                        updateState({ openWithdraw: true });
                    }}
                    onClose={handleSpinClose}
                    buttonAllHighlight={true}
                />
            </PopupCard>
        );
    };

    const renderMainContent = () => (
        <Container>
            <Box
                display="flex"
                justifyContent="space-between"
                height = "80px"
                top={0}
                left={0}
                right={0}
                sx={{ position: 'relative', zIndex: 2 }}  // 确保使用相对定位，并设置zIndex
            >
                <ButtonWithLogo imageSrc="/icon-rule.webp" text="Rules" onClick={handleRuleButtonPress} />
                <ButtonWithLogo imageSrc="/icon-invite.webp" text="Invitation" onClick={handleInvitationButtonPress} />
                <ButtonWithLogo imageSrc="/icon-gold.webp" text="Cash Out" onClick={handleWithdrawButtonPress} />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" mt={-3} mb={0} sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <SlotMachine
                    active={machineActive}
                    result={rewards}
                    handleFinish={handleSpinStop}
                />
            </Box>
            
            <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: -3 }}>
                <LinearProgressBar current={progressShow} total={progressMaxShow}>
                    <Typography variant="h5" sx={{ fontStyle: 'italic', fontWeight: 'bold', color: '#F39C12', textShadow: '1px 1px 2px #404040' }}>
                        ₹{progressShow}
                    </Typography>
                </LinearProgressBar>
            </Box>

            <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: -2 }}>
                <Typography variant="h7" sx={{ fontStyle: 'italic', fontWeight: 'bold', color: '#FFFFFF' }}>
                    Only <span style={{ color: '#FFEBA2' }}>₹{progressMaxShow - progressShow}</span> to cash out ₹{progressMaxShow}
                </Typography>
            </Box>

            <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt:1, gap: 2 }}>
                <ButtonWithIcon color="yellow" onClick={handleSpinButtonPress}>
                    <img src="/icon-diamond.png" alt="icon" style={{ width: '38.4px', height: '33.3px', marginRight: '0px' }} />
                    <Typography variant="h8" sx={{ fontStyle: 'italic', fontWeight: 'bold', color: '#FFFFFF', marginRight: '20px', textShadow: '1px 1px 2px #000000', marginTop: '20px' }}>
                        x{priceShow}
                    </Typography>
                    <Typography variant="h5" sx={{ fontStyle: 'italic', fontWeight: 'bold', color: '#FFFFFF', textShadow: '1px 1px 2px #000000' }}>
                        Play to Earn
                    </Typography>
                </ButtonWithIcon>
            </Box>

            <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 0.5 }}>
                <Typography variant="h7" sx={{ fontStyle: 'italic', fontWeight: 'bold', color: '#FFFFFF', display: 'flex', alignItems: 'center' }}>
                    You have
                    <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                    x{ticketNumber}
                </Typography>
            </Box>

            <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1, mb:2, gap: 2 }}>
                <ButtonWithIcon color="blue" onClick={handleInvite} >
                    <img src="/icon-invite.webp" alt="icon" style={{ width: '40px', height: '40px', marginLeft: '0px', marginRight: '20px' }} />
                    <Typography variant="h5" sx={{ fontStyle: 'italic', fontWeight: 'bold', color: '#FFFFFF', textShadow: '1px 1px 2px #000000' }}>
                        Invite for Gems
                    </Typography>
                </ButtonWithIcon>
            </Box>

            {taskInfos.length > 0 && <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2, mb: 2, gap: 2 }}>
                <ButtonWithIcon color="purple" onClick={handleTaskButtonPress} >
                    <img src="/icon-task.png" alt="icon" style={{ width: '32px', height: '32px', marginLeft: '0px', marginRight: '20px' }} />
                    <Typography variant="h5" sx={{ fontStyle: 'italic', fontWeight: 'bold', color: '#FFFFFF', textShadow: '1px 1px 2px #000000' }}>
                        Task for Gems
                    </Typography>
                </ButtonWithIcon>
            </Box> }
        </Container>
    );

    return (
        <Box
            sx={{
                background: 'radial-gradient(circle, rgba(36,36,64,1) 0%, rgba(16,17,30,1) 100%)', // 中心渐变背景
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {initState === 'loading' && renderLoading()}
            {initState === 'success' && (
                <>
                    {loginState === 'loading' && renderLoading()}
                    {loginState === 'successUnjoined' && renderJoinUs()}
                    {loginState === 'check' && renderJoinCheck()}
                    {loginState === 'error' && renderLoginError()}
                </>
            )}

            {renderMainContent()}

            {spinState === 'loading' && renderLoading()}
            {spinState === 'insufficient' && renderInsufficientTickets()}
            {spinState === 'error' && renderSpinError()}
            {spinState === 'withdraw' && renderNeedWithdraw()}
            {spinState === 'finished' && renderSpinFinished()}

            {openRules && <Rules
                open={true}
                onClose={() => updateState({ openRules: false })}
            />}
            {openInvitation && <Invitation
                open={true}
                onClose={() => updateState({ openInvitation: false })}
            />}
            {openWithdraw && <Withdraw
                open={true}
                onClose={() => updateState({ openWithdraw: false })}
                getTicket={getTicketNumber}
                updateTicket={updateTicketNumber}
                miniAppVisitUrl={miniAppVisitUrl.current}
                upiPayEnable={upiPayEnable.current}
            />}
            {openTask && <Tasks
                open={true}
                onClose={() => updateState({ openTask: false })}
                taskInfos={taskInfos}
                setTaskInfos={setTaskInfos}
                updateTicket={updateTicketNumber}
            />}
        </Box>
    );
}

export default App;
