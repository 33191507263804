import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

const getRandomNumber = () => Math.floor(Math.random() * 10);
const getNextNumber = (num) => (num + 1) % 10;
const getResultArray = (result) => result.toString().padStart(3, '0').split('').map(Number);

const SlotMachine = ({ active, result, handleFinish }) => {
    const [numbers, setNumbers] = useState([0, 0, 0]);
    const intervalRefs = useRef([null, null, null]); // ʹ�� useRef ����һ���ɱ����õ�����

    useEffect(() => {

        if (active === false) {
            intervalRefs.current.forEach(clearInterval); // ������м�ʱ��
            return;
        }

        // get result
        const resultArray = getResultArray(result);
        // init
        setNumbers([getRandomNumber(), getRandomNumber(), getRandomNumber()]);

        // Ϊÿ���������������ļ�ʱ��
        intervalRefs.current = intervalRefs.current.map((_, index) => {
            return setInterval(() => {
                setNumbers((prevNumbers) => {
                    const newNumbers = [...prevNumbers];
                    newNumbers[index] = getNextNumber(newNumbers[index]);
                    return newNumbers;
                });
            }, 50);
        });

        // ����ÿ������ֹͣ��ʱ��
        const stopTimes = [2000, 2500, 3000];

        stopTimes.forEach((time, index) => {
            setTimeout(() => {
                clearInterval(intervalRefs.current[index]); // ֹͣ��ǰ��ʱ��
                setNumbers((prevNumbers) => {
                    const newNumbers = [...prevNumbers];
                    newNumbers[index] = resultArray[index];
                    return newNumbers;
                });
                if (index === stopTimes.length - 1) {
                    setTimeout(() => {
                        handleFinish();
                    }, 500); // �����һ������ͣ�º�һ��ʱ���ٵ��� handleFinish
                }
            }, time);
        });
    }, [active, result, handleFinish]);

    return (
        <Box
            position="relative"
            width="22.5rem"  // ʹ��rem��λ
            height="22.4rem"  // ʹ��rem��λ
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundImage: 'url(/SlotMachine.webp)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontSize: '62.5%', // ���ø�Ԫ�������СΪ 10px������ rem �Ļ���
            }}
        >
            <Box
                position="absolute"
                top="43.5%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex={2}
                width="0px"
                height="0px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                    {numbers.map((number, index) => (
                        <motion.div
                            key={index}
                            animate={{ y: [0, 0] }}
                            transition={{ duration: 0.5 }}
                            style={{
                                width: '0rem',  // ʹ��rem��λ
                                height: '0rem',  // ʹ��rem��λ
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '6rem',
                                margin: '0 2.25rem',  // ʹ��rem��λ
                                color: '#000000',
                            }}
                        >
                            {number}
                        </motion.div>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default SlotMachine;
