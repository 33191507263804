import React, { useState, useCallback, useRef } from 'react';
import { Typography, Box } from '@mui/material';
import { openTelegramLink } from '../tools/TgTools';
import { postData } from '../communications/Backend';
import { trackEvent, trackException } from '../tools/GaTools';
import PopupCard from '../components/PopupCard'; // ���� PopupCard ���
import PopupTitle from '../components/PopupTitle';
import PopupInfo from '../components/PopupInfo';
import PopupContent from '../components/PopupContent';
import ButtonSingle from '../components/ButtonSingle';
import TaskCard from '../components/TaskCard';
import LoadingSpinner from '../components/LoadingSpinner';
export function Tasks({ open, onClose, taskInfos, setTaskInfos, updateTicket }) {
    const welcome = `Complete tasks to earn more Gems!`;
    const [checkState, setCheckState] = useState('idle'); // 'idle', 'loading', 'successJoined', 'successUnjoined', 'check', 'error'
    const [ticketNumber, setTicketNumber] = useState(0);
    const chosenTaskChannelLinkRef = useRef(null);
    const TaskIntroduction = () => {
        return (
            <>
                <Typography
                    variant="body1"
                    width="100%"
                    sx={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        color: '#FFFFFF',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                        textAlign: 'left', // �ı�����
                        whiteSpace: 'pre-line', // �������з�
                        mt: 1,
                    }}>
                    {welcome}
                </Typography>
            </>
        );
    }

    const checkTaskState = useCallback(async (task) => {
        setCheckState('loading');

        trackEvent({
            category: 'User',
            action: 'TaskCheck',
            label: "Start",
        });

        try {
            await postData(
                'check-task',
                { channelName: task.channelName },
                (data) => {
                    setTicketNumber(data.ticketNumber);
                    updateTicket(data.ticketNumber);

                    let checkStateTmp = checkState;
                    let taskState = 'join';
                    if (data.taskStatus === 'Incomplete') {
                        if (task.state === 'join') {
                            checkStateTmp = 'unjoinedFirst';
                            if (chosenTaskChannelLinkRef.current != null) {
                                openTelegramLink(chosenTaskChannelLinkRef.current);
                            }
                        } else {
                            checkStateTmp = 'unjoinedSecond';
                        }
                        taskState = 'get';
                    } else if (data.taskStatus === 'Completed') {
                        checkStateTmp = 'successJoined';
                        taskState = 'done';
                    } else if (data.taskStatus === 'PreviouslyCompleted') {
                        checkStateTmp = 'error';
                        taskState = 'join';
                    }
                    setCheckState(checkStateTmp); 
                    setTaskStateInTaskInfos(taskInfos, task.channelId, taskState);

                    trackEvent({
                        category: 'User',
                        action: 'TaskCheck',
                        label: 'Finished',
                    });
                },
                (error) => {
                    setTaskStateInTaskInfos(taskInfos, task.channelId, true);
                    setCheckState('error');
                    console.error(`Task Check Error ${task.channelId}`, error);
                    trackException({
                        description: `Task Check Error ${task.channelId} ${error}`,
                        fatal: true,
                    });
                }
            );
        } catch (error) {
            setTaskStateInTaskInfos(taskInfos, task.channelId, true);
            setCheckState('error');
            console.error(`Task Check Error ${task.channelId}`, error);
            trackException({
                description: `Task Check Error ${task.channelId} ${error}`,
                fatal: true,
            });
        }
    }, [setCheckState, taskInfos, openTelegramLink]);

    const setTaskStateInTaskInfos = (taskInfos, taskChannelId, state) => {

        const updatedTaskInfos = taskInfos.map(t => {
            if (t.channelId === taskChannelId) {
                return { ...t, state: state }; // �����µ�������󲢸���״̬
            }
            return t;
        });

        setTaskInfos(updatedTaskInfos); // ����������Ϣ����
    }

    const handleTaskButtonClick = useCallback((task) => {
        if (task.state != 'done') {
            chosenTaskChannelLinkRef.current = task.channelLink;
            checkTaskState(task);
        }
    }, [checkTaskState])

    const handleTaskCheckClose = useCallback(() => {
        setCheckState('idle');
        trackEvent({
            category: 'User',
            action: 'TaskCheck',
            label: 'ResumeIdle',
        });
    }, [setCheckState]);

    const handleJoinClick = useCallback(() => {
        if (chosenTaskChannelLinkRef.current != null) {
            openTelegramLink(chosenTaskChannelLinkRef.current);
        }
        setCheckState('idle');
        trackEvent({
            category: 'User',
            action: 'TaskCheck',
            label: 'ResumeIdle',
        });
    }, [setCheckState]);


    function capitalizeFirstLetter(string) {
        if (typeof string !== 'string' || string.length === 0) {
            return ''; // �������ַ�������ַ��������
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const renderTaskCheckError = () => {
        const info = `Network connection error
                      Try again later!`;
        return (
            <PopupCard open={true}>
                <PopupTitle title="Error" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={handleTaskCheckClose} clickInfo="Retry" />
            </PopupCard>
        );
    };

    const renderTaskCheckFinished = () => {
        const info = (
            <>
                You have received
                <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                x1
                {'\n'}
                Now you have
                <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                x{ticketNumber}
            </>
        );
        return (
            <PopupCard open={true}>
                <PopupTitle title="Congratulations" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={handleTaskCheckClose} clickInfo="Continue" />
            </PopupCard>
        );
    };


    const renderTaskTips = () => {
        const info = (
            <>
                Join channel first
                {'\n'}
                Then you can get
                <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                x1
            </>
        );
        return (
            <PopupCard open={true}>
                <PopupTitle title="Tips" />
                <PopupContent>
                    <PopupInfo info={info} />
                </PopupContent>
                <ButtonSingle onClick={handleJoinClick} clickInfo="Join" />
            </PopupCard>
        );
    };

    const renderTaskDescription = (index, reward) => {
        return (
            <>
                <Typography
                    variant="body1"
                    component="h1"
                    sx={{
                        fontSize: '1.2rem',
                        textAlign: 'left',
                        color: '#FFFFFF',
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                    }}
                >
                    Join Channel {index + 1}
                </Typography>
                <Typography
                    variant="body1"
                    component="h1"
                    sx={{
                        fontSize: '1.2rem',
                        textAlign: 'left',
                        color: '#FFFFFF',
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.3)', // ����������Ӱ
                    }}
                >
                    Get
                    <img src="/icon-diamond.png" alt="icon" style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                    x{reward}
                </Typography>
            </>
        );
    };

    return (
        <>
            <PopupCard
                open={open}
                onClose={onClose}
                width="100%"
            >
                <PopupTitle title="Tasks" />
                <PopupContent>
                    <TaskIntroduction />
                    {taskInfos.map((task, index) => (
                        <TaskCard
                            key={index}
                            description={renderTaskDescription(index, 1)}
                            buttonText={capitalizeFirstLetter(task.state)}
                            buttonAction={() => handleTaskButtonClick(task)}
                            buttonEnable={task.state != 'done'}
                            sx={{ mt: index === 0 ? 2 : 1, mb: 1, gap: 2 }}
                        />
                    ))}
                </PopupContent>
                <ButtonSingle onClick={onClose} clickInfo="Close" />
            </PopupCard>

            {checkState === 'loading' && <LoadingSpinner />}
            {checkState === 'successJoined' && renderTaskCheckFinished()}
            {checkState === 'unjoinedSecond' && renderTaskTips()}
            {checkState === 'error' && renderTaskCheckError()}
        </>
    );
}
