import React from 'react';
import { Box } from '@mui/material';

const PopupCentent = ({children}) => {
    return (
        <Box sx={{
            width: '100%',
            display: 'flex', // ʹ�� flex ����
            flexDirection: 'column', // ȷ����Ԫ�ش�ֱ����
            justifyContent: 'flex-start', // ��Ԫ���������ж�������
            alignItems: 'center', // ��Ԫ��ˮƽ����
            overflowY: 'auto',
            maxHeight: '60vh',
        }}>
            {children}
        </Box>
    );
}

export default PopupCentent;